@if (isCurrentUserSubmissionAuthor) {
    <div class="col-12 mt-4">
        <div class="row">
            <div class="col-12 col-md-6">
                <h3 [jhiTranslate]="complaintType === ComplaintType.COMPLAINT ? 'artemisApp.complaint.title' : 'artemisApp.moreFeedback.title'"></h3>
                @if (complaintType !== ComplaintType.COMPLAINT) {
                    <p>
                        {{ (exercise.teamMode ? 'artemisApp.moreFeedback.descriptionTeam' : 'artemisApp.moreFeedback.description') | artemisTranslate }}
                        {{ 'artemisApp.moreFeedback.info' | artemisTranslate }}
                    </p>
                }
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-md-6">
                <p>
                    @if (exercise.teamMode) {
                        <span
                            [jhiTranslate]="complaintType === ComplaintType.MORE_FEEDBACK ? 'artemisApp.moreFeedback.beDescriptiveTeam' : 'artemisApp.complaint.beDescriptiveTeam'"
                        ></span>
                    }
                    @if (!exercise.teamMode) {
                        <span
                            [jhiTranslate]="complaintType === ComplaintType.MORE_FEEDBACK ? 'artemisApp.moreFeedback.beDescriptive' : 'artemisApp.complaint.beDescriptive'"
                        ></span>
                    }
                </p>
                @if (!examId) {
                    <p jhiTranslate="artemisApp.complaint.exclusivityDisclaimer"></p>
                }
                <div class="d-flex flex-column">
                    <textarea id="complainTextArea" class="col-12 px-1" rows="4" [maxLength]="this.maxComplaintTextLimit" [(ngModel)]="complaintText"> </textarea>
                    <jhi-textarea-counter [maxLength]="this.maxComplaintTextLimit" [content]="complaintText" [visible]="true" />
                </div>
                <div class="row">
                    <div class="col-6">
                        <button
                            id="submit-complaint"
                            class="btn btn-primary"
                            [disabled]="!complaintText || complaintTextLength() > this.maxComplaintTextLimit"
                            (click)="createComplaint()"
                        >
                            {{
                                complaintType === ComplaintType.COMPLAINT
                                    ? ('artemisApp.complaint.submit' | artemisTranslate)
                                    : ('artemisApp.moreFeedback.button' | artemisTranslate)
                            }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
}
